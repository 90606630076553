/*
Paleta official:
Verd clar
#E0E722
RGB (224,231,34)

Verd primari:
#97D700
RGB (151,215,0

Gris secundari:
#4D4D4D
RGB (77,77,77)

Degradatas neger del 90 al 60%

COLOR DEL MIG DEL
DEGRADAT DELS DOS
COLORS CORPORATIUS
PRINCIPALS
#AFBE3F
RGB (175,190,63)

*/
$green2: #2c8942;
$gray-opacity: rgba(26, 24, 29, .2);
$gray: #dedede;

a {
  color: var(--secondary) !important;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--secondary) !important;
    text-decoration: none;
  }

  &.primary {
    color: var(--primary) !important;
    text-decoration: none;
  }

  &.secondary {
    color: var(--secondary) !important;
    text-decoration: none;
  }

  &.consultations-header__link {
    color: #fff !important;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  &.card {
    text-decoration: none;
  }
}

.section-heading a {
  text-decoration: none;
}

.text-highlight a {
  color: #fff !important;
  text-decoration: none;
}

.link {
  color: var(--primary) !important;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--secondary);
  }
}

.hero {
  &::after {
    background-color: $gray-opacity;
  }
}

.button {
  color: #fff !important;
  text-decoration: none;

  &.hollow {
    border-color: var(--secondary) !important;
    color: var(--secondary) !important;

    &:hover,
    &:focus {
      background-color: rgba(var(--secondary-rgb), .2) !important;
    }
  }
}

.consultations-card {
  .button.success {
    border-color: var(--secondary) !important;
    color: var(--secondary) !important;
    text-decoration: none;
    background: transparent;
  }
}

.card__link {
  text-decoration: none;

  &.card__title {
    color: var(--primary) !important;
  }
}

h2#process_title {
  color: var(--primary) !important;
  text-decoration: none;
}

.floating-helper__content-close {
  svg.icon {
    fill: #fff;
  }
}

.topbar__search {
  input {
    background: #fff;
    border-color: $gray;
  }

  &:not(:focus-within) {
    button {
      background: $gray;
    }
  }
}

h2,
.h2 {
  font-size: 1.6em;
}

h3,
.h3 {
  font-size: 1.45rem;
}

.topbar__dropmenu {
  >ul {
    >li {
      >a {
        &::after {
          border-top-color: var(--secondary) !important;
        }

        color: var(--secondary);
      }
    }
  }

  .is-dropdown-submenu {
    li {
      a {
        color: var(--secondary) !important;
      }
    }
  }
}

.navbar {
  background: #fff;

  a,
  .button {
    text-decoration: none;

    &:focus {
      outline-color: $gray;
    }
  }
}

.main-nav__link {
  a {
    position: relative;
    text-decoration: none;
    display: block;
    padding: 1em 2em .1em;

    &:hover {
      background: #fff;
      text-decoration: underline;
    }
  }
}

.main-nav__link--active {
  a {
    text-decoration: none;
    font-weight: 600;
    background: #fff !important;
    box-shadow: 0 3px var(--primary);
  }
}

.usermenu-off-canvas-holder .usermenu-off-canvas a {
  color: $gray !important;
}

main#content {
  margin-top: 3px;
}

.process-nav__link {
  color: var(--secondary) !important;
  text-decoration: none;

  &.active {
    color: #000 !important;
  }
}

.process-header__main {
  &::after {
    background-color: $gray-opacity;
  }
}

.comment__reply.muted-link {
  color: var(--primary);
  text-decoration: none;
}

.comment__reply[data-toggle].muted-link {
  color: var(--primary);
  text-decoration: none;
}

.link-alt {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
}

.label.success {
  background: #39a935;
  color: #fff;
  text-decoration: none;
}

.progress__bar {
  .progress__bar__bar {
    .progress__bar__bar--complete {
      border-radius: 0;
      background-color: var(--primary);
      height: 100%;
    }
  }
}

.card.success {
  border-top-width: 8px;
  border-top-color: var(--primary);
}

.success.chart-tooltip {
  border-top-width: 8px;
  border-top-color: var(--primary);
}

.success.conference-registration {
  border-top-width: 8px;
  border-top-color: var(--primary);
}

.conference-speaker {
  .success.speaker-bio {
    border-top-width: 8px;
    border-top-color: var(--primary);
  }
}

.success.add-message {
  border-top-width: 8px;
  border-top-color: var(--primary);
}

.success.comment-thread {
  border-top-width: 8px;
  border-top-color: var(--primary);
}

.author-data {
  .author__name {
    color: var(--secondary);
    margin-bottom: 0;
    text-decoration: none;
  }
}

.card__text {
  .card__text--status.success {
    color: var(--primary);
    text-decoration: none;
  }
}

.main-footer {
  background-color: $gray;

  a {
    color: #5b5b5f !important;
    text-decoration: none;
  }
}

.cookie-warning {
  display: none !important;
  visibility: hidden;
}

.title-bar {
  background-color: #fff;
}

@media print, screen and (max-width: 39.9988em) {
  .title-bar {
    border-top-color: $gray;
  }

  .topbar__edit__link {
    margin: -.5rem 0 0 .5rem;
  }
}

.topbar__menu {
  color: var(--secondary);
  text-decoration: none;
}

.main-footer__badge {
  width: 90px;
}

.mini-footer {
  .decidim-logo {
    display: none;
  }

  a {
    color: $gray !important;
    text-decoration: underline;
  }
}

.topbar__notifications,
.topbar__conversations {
  .icon {
    fill: #585858;
  }
}
